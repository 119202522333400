import request from "tools/request.js"

class huiyuan_get {
	constructor(parent) {
	    this.parent = parent
	}
	
	m_main() {
		const that = this;
		
		let page 	  		 = that.parent.page;
		let page_size		 = that.parent.page_size;
		
		
		let m_huiyuan_id = that.parent.m_huiyuan_id; 
		 
		let session = localStorage.getItem("session");
		
		let params = {
			page : page,
			size : page_size, 
			m_huiyuan_id : m_huiyuan_id, 
			session : session
		} 
		
		request.post('/huiyuan/chongzhijilu_get',params).then(res => {
			 if(res.data.state == 902){
			 	this.parent.$notify({
			 		title: '提示',
			 		message: "登陆过期 即将跳转到 登陆",
			 		type: 'success'
			 	});
			 	
			 	setTimeout(function (){ 
			 	    that.parent.$router.push({path:'/login',query:{}}); 
			 	}, 2000); 
			 }
			that.parent.m_chongzhijilu_list = res.data.result; 
			that.parent.max_page = res.data.count;
		})
		 
	}
}

export default huiyuan_get